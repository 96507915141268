import React, {useContext, useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import UserData from "../store/User/UserData";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Auth} from "aws-amplify";
import * as Sentry from "@sentry/react";
import Nav from "./Nav";
import {getTenant} from "../api/ApiClient";
import '../styles/navigation/Nav.scss';
import Dashboard from "../pages/Dashboard";
import NotFound from "./NotFound";
import Detections from "../pages/Detections";
import LogSearch from "../pages/LogSearch"
import Hosts from "../pages/Hosts"
import Vulnerabilities from "../pages/Vulnerabilites";
import Hygiene from "../pages/Hygiene/Hygiene"
import Investigations from "../pages/Investigations/Investigations";
import RedirectComponent from './RedirectComponent';
import FileStorage from "../pages/FileStorage/FileStorage";
import {BrowserStorageCache} from '@aws-amplify/cache';
import {
  checkTenantType,
  hasAdminAccess,
  isAZUser,
  isMDRLite,
  isMobileMDRUser,
  isMobileOnly,
  isRoot,
  showLogSearch
} from '../util/userControl';
import Connections from "../pages/Connections";
import Mobile from '../pages/Mobile';
import MaturityAssessment from "../pages/MaturityAssessment";
import Onboarding from "../pages/Onboarding";
import NewTenant from '../pages/Onboarding/Tenants/NewTenant';
import SupportTicket from '../pages/SupportTicket'
import Preferences from '../pages/Preferences/Preferences';
import KnowledgeGraph from "../pages/KnowledgeGraph/KnowledgeGraph";
import GlobalState from "../store/GlobalState/GlobalState";
import Network from '../pages/Network/Network';
import MultiTenantDashboard from "../pages/MultiTenantDashboard/MultiTenantDashboard";
import {captureSentryError} from "../util/sentry";
import Vulnerabilities2 from "../pages/Vulnerabilities2";

const max_user_inactivity_ms = 3600000;

const NavigationRoutes = () => {
  const [userData] = useContext(UserData);
  const [showLogSearchTab, setLogSearchTab] = useState(true)
  const mobileOnly = isMobileOnly(userData)
  const isMobileMDR = isMobileMDRUser(userData);
  const mdrLite = isMDRLite(userData)
  const isParent = checkTenantType(userData, 'is_parent')
  const isOnboarding = checkTenantType(userData, 'is_onboarding')
  const [azState] = useContext(GlobalState);

  useEffect(() => {
    userData?.userType && userData?.tenantConfig && setLogSearchTab(showLogSearch(userData?.tenantConfig, userData?.userType))
  }, [userData?.tenantConfig, userData?.userType])

  const getRoutes = () => {
    if (isParent || isOnboarding) {
      return (
        <Routes>
          {isParent && <Route path="/" element={<MultiTenantDashboard/>}/>}
          <Route path="/onboarding" element={<Onboarding/>}/>
          <Route path="/onboarding/people" element={<Onboarding route="people"/>}/>
          {isParent && <Route path="/onboarding/tenants" element={<Onboarding route="tenants"/>}/>}
          {isRoot(userData?.userType) && userData?.tenant === 'actzero-parent' &&
          <Route path="/onboarding/tenants/create" element={<NewTenant/>}/>}
          <Route path="/preferences" element={<Preferences/>}/>
          <Route path="/preferences/2FA" element={<Preferences route="2FA"/>}/>
          <Route path='*' element={<Onboarding/>}/>
          <Route path="/help/support-ticket" element={<SupportTicket/>}/>
          <Route path='/help/support-ticket/:id' element={<RedirectComponent path={'/help/support-ticket?id='}/>}/>
        </Routes>
      )
    }
    return (
      <Routes>
        <Route path="/" element={userData?.page ? <Navigate to={userData.page} replace/> : <Dashboard/>}/>
        <Route path="/activity/investigations" element={<Investigations/>}/>
        <Route path='/case/:id' element={<RedirectComponent path={'/activity/investigations?case='}/>}/>
        <Route path="/activity/detections" element={<Detections/>}/>
        <Route path='/detection/:id' element={<RedirectComponent path={'/activity/detections?id='}/>}/>
        <Route path='/activity/zeroin' element={<KnowledgeGraph/>}/>
        <Route path="/activity/network" element={<Network/>}/>
        {showLogSearchTab && !mobileOnly && !mdrLite && <Route path="/activity/log-search" element={<LogSearch/>}/>}
        {!mobileOnly && <Route path="/connections/endpoints" element={<Hosts/>}/>}
        {!mobileOnly && !mdrLite && <Route path="/risk-management/vulnerabilities" element={<Vulnerabilities/>}/>}
        {!mobileOnly && !mdrLite && (azState?.flags?.hasNewVulnAccess || isAZUser(userData)) &&
        <Route path="/risk-management/vulnerabilities2" element={<Vulnerabilities2/>}/>}
        {!mobileOnly && !mdrLite && <Route path="/risk-management/cloud-posture" element={<Hygiene/>}/>}
        {!mobileOnly && !mdrLite &&
        <Route path="/risk-management/maturity-assessment" element={<MaturityAssessment/>}/>}
        <Route path="/reports/files" element={<FileStorage/>}/>
        <Route path="/connections/service-status" element={<Connections/>}/>
        <Route path="/connections/mobile-devices" element={<Mobile/>}/>
        {hasAdminAccess(userData?.role) &&
        <>
          <Route path="/onboarding" element={<Onboarding/>}/>
          <Route path="/onboarding/people" element={<Onboarding route="people"/>}/>
          {!mobileOnly && <Route path="/onboarding/endpoints" element={<Onboarding route="endpoints"/>}/>}
          {!mobileOnly && <Route path="/onboarding/integrations" element={<Onboarding route="integrations"/>}/>}
          {isMobileMDR && <Route path="/onboarding/mobile" element={<Onboarding route="mobile"/>}/>}
          <Route path="/onboarding/tenants" element={<Onboarding route="tenants"/>}/>
          {isRoot(userData?.userType) && userData?.tenant === 'actzero-parent' &&
          <Route path="/onboarding/tenants/create" element={<NewTenant/>}/>}
          <Route path="/onboarding/vulnerability-scan" element={<Onboarding route="vulnerability-scan"/>}/>
          <Route path="/onboarding/virtual-machine" element={<Onboarding route="virtual-machine"/>}/>
          <Route path="/onboarding/network" element={<Onboarding route="network"/>}/>
        </>}
        <Route path="/preferences" element={<Preferences/>}/>
        <Route path="/preferences/2FA" element={<Preferences route="2FA"/>}/>
        <Route path="/help/support-ticket" element={<SupportTicket/>}/>
        <Route path='/help/support-ticket/:id' element={<RedirectComponent path={'/help/support-ticket?id='}/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    )
  }

  return getRoutes()
}

const Router = () => {
  const [userData, setUserData] = useContext(UserData);
  const [azState] = useContext(GlobalState);

  async function signOut() {
    try {
      await Auth.signOut();
      BrowserStorageCache.clear();
      window.location.href = '/';
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    const getTenantConfiguration = async () => {
      try {
        let tenantConfig = await getTenant(userData?.tenant);
        setUserData((state) => ({...state, tenantConfig}));
      } catch (aggregateError) {
        captureSentryError(aggregateError, userData, 'getTenant in getTenantConfiguration')
      }
    };
    userData?.tenant && getTenantConfiguration()
    // eslint-disable-next-line
  }, [userData?.tenant, setUserData])

  useEffect(() => {
    handleLatestIdle();
    // eslint-disable-next-line
  }, [])

  const setLatestActiveTime = () => BrowserStorageCache.setItem("latest_activity_time", Date.now());

  const handleLatestIdle = () => {
    const latestActivityTime = BrowserStorageCache.getItem("latest_activity_time");
    if (latestActivityTime)
      (Date.now() - latestActivityTime + 5000) > max_user_inactivity_ms && signOut(); // 5 second buffer added for the onAction 2 second delay (i.e. debounce)
    else setLatestActiveTime();
  }

  useIdleTimer({
    onIdle: () => handleLatestIdle(),
    onAction: () => setLatestActiveTime(),
    debounce: 2000,
    timeout: max_user_inactivity_ms
  })

  return (
    <div id="router" className={"router-container"}>
      {azState?.loadingIsParent === false &&
      <BrowserRouter>
        <Nav/>
        <NavigationRoutes/>
      </BrowserRouter>}
    </div>
  );
}

export default Router;