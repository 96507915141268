import React, {useEffect, useRef, useState} from "react";

export const TableRow = ({label, value, valueClassName}) => {
  const [toggleDescription, setToggleDescription] = useState(false)
  const [isTruncated, setIsTruncated] = useState(true);
  const [isExpandable, setIsExpandable] = useState(false)
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (label === 'Description' && descriptionRef.current) {
      const element = descriptionRef.current;
      const isOverflowing = element.scrollHeight >= element.clientHeight;
      isOverflowing && setIsExpandable(true)
      setIsTruncated(isOverflowing);
    }
  }, [value, toggleDescription, label]);

  const formatClassname = () => {
    let name = label.split(' ')[0] + '' + (valueClassName ? valueClassName : '') + (value === undefined ? ' no-value' : '')
    if (label === 'Description') {
      if (isExpandable) name = name + ' pointer'
      if (toggleDescription) name = name.split(' ').slice(1).join(' ');
    }
    return name
  }
  const handleClick = () => {
    if (window.getSelection().toString()) {
      return;
    }
    label === 'Description' && setToggleDescription(!toggleDescription);
  };

  const handleVal = () => {
    return typeof value == "string" && value?.startsWith("CVE") ?
      <a className={"cve-label"} href={`https://nvd.nist.gov/vuln/detail/${value}`} target="_blank" rel="noreferrer">
        <span>{value}</span>
      </a>
      : <span>{value.toString()}</span>
  }

  return (
    <tr>
      <td className={"label"}>{label}</td>
      <td className={formatClassname()} onClick={handleClick} ref={label === 'Description' ? descriptionRef : null}>
        {handleVal()}
      </td>
      {(label === 'Description' && isTruncated && !toggleDescription) &&
      <td className="expand-description" onClick={handleClick}>EXPAND</td>}
    </tr>
  )
}

export const ExpandedTableList = ({data, title, labels}) =>
  <table className={"threat-table left " + title}>
    <tbody>
    <tr>
      <td className={"title"}>{title}</td>
    </tr>
    {data && labels.some(item => data[item]) ?
      labels.map((item, i) => (data && data[item]) && <TableRow key={i + item} label={item} value={data[item]}/>)
      : <p className={"no-data"}>No Data</p>}
    </tbody>
  </table>
