import GoogleAuthenticator from "../../assets/icons/google-authenticator.svg";
import Duo from "../../assets/icons/duo.svg";
import MicrosoftAuthenticator from "../../assets/icons/microsoft-authenticator.svg";

export const tabConfig = [
  { display: "App 2FA", configName: "app2fa" },
  { display: "SMS 2FA", configName: "sms2fa" },
];

export const applicationIcons = [
  {
    label: "Google Authenticator",
    className: "google",
    img: GoogleAuthenticator,
    width: "70px",
  },
  { label: "Duo", className: "duo", img: Duo, width: "90px" },
  {
    label: "Microsoft Authenticator",
    className: "microsoft",
    img: MicrosoftAuthenticator,
    width: "70px",
  },
];
