import React, { useState, useEffect } from "react";
import "../../../styles/pages/Preferences/MFAPreferences.scss";
import { Auth } from "aws-amplify";
import Tab from "../../../components/Tab/Tab";
import { tabConfig } from "../config";
import Sms2FA from "./Sms2FA";
import App2FA from "./App2FA";

const MFAPreferences = () => {
  const [cognitoUserInfo, setCognitoUserInfo] = useState();
  const [currTab, setCurrTab] = useState("app2fa");
  const [currentMFASetting, setCurrentMFASetting] = useState("");

  useEffect(() => {
    async function getCognitoUserInfo() {
      const userInfo = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setCurrentMFASetting(userInfo?.preferredMFA);
      setCognitoUserInfo(userInfo);
    }
    getCognitoUserInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={"MFA-container"}>
      <div className="header">Two-Factor Authentication</div>
      {tabConfig.map((tab) => (
        <Tab
          data={tab}
          onClick={() => setCurrTab(tab.configName)}
          selected={currTab === tab.configName}
          key={tab.configName}
        />
      ))}
      {currTab === "sms2fa" && (
        <Sms2FA
          cognitoUserInfo={cognitoUserInfo}
          currentMFASetting={currentMFASetting}
          setCurrentMFASetting={setCurrentMFASetting}
        />
      )}
      {currTab === "app2fa" && (
        <App2FA
          cognitoUserInfo={cognitoUserInfo}
          currentMFASetting={currentMFASetting}
          setCurrentMFASetting={setCurrentMFASetting}
        />
      )}
    </div>
  );
};

export default MFAPreferences;
