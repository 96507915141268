import React, { useState, useEffect } from "react";
import "../../../styles/pages/Preferences/App2FA.scss";
import { Auth } from "aws-amplify";
import QRCodeCanvas from "qrcode.react";
import { postUserMFASetting } from "../../../api/ApiClient";
import Grid from "@mui/material/Grid";
import { Loader } from "../../../components/Loader/Loader";
import CheckIcon from "@mui/icons-material/Check";
import { applicationIcons } from "../config";
import ErrorIcon from "@mui/icons-material/Error";
import config from "../../../config"

const App2FA = ({
  cognitoUserInfo,
  currentMFASetting,
  setCurrentMFASetting,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [appError, setAppError] = useState("");
  const [qrLoading, setQrLoading] = useState(true);
  const [qrError, setQrError] = useState(false);
  const [qrCodeURL, setQrCodeURL] = useState("");
  const [totpToken, setTotpToken] = useState("");
  const [appSuccess, setAppSuccess] = useState(false);
  const [showManualQRcode, setShowManualQRcode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleApp2FASetup = async () => {
    setSubmitLoading(true);
    setAppError("");
    let errorMessage;
    try {
      let response;
      try {
        response = await Auth.verifyTotpToken(
          cognitoUserInfo, // Return object from Auth.signIn()
          verificationCode // Confirmation code
        );
      } catch {
        errorMessage = "Invalid code entered";
      }
      if (response.Status === "SUCCESS") {
        await postUserMFASetting("totp");
        setShowManualQRcode(false);
        setAppSuccess(true);
        setCurrentMFASetting("SOFTWARE_TOKEN_MFA");
        setQrCodeURL("");
      }
    } catch {
      setAppError(errorMessage || "An error has occurred");
    }
    setSubmitLoading(false);
  };

  const handleManualQRCode = () => {
    setShowManualQRcode(!showManualQRcode);
  }

  useEffect(() => {
    const getQRCode = async () => {
      try {
        const totp = await Auth.setupTOTP(cognitoUserInfo);
        setTotpToken(totp);
        let qrcodeTotpUrl =
          "otpauth://totp/" +
          cognitoUserInfo.attributes.email +
          "?secret=" +
          totp +
          "&issuer=ActZero.ai";
        if (config.env !== 'prod'){
           const envCapitalized = (config.env).charAt(0).toUpperCase() + (config.env).slice(1)
           qrcodeTotpUrl = qrcodeTotpUrl + '(' + envCapitalized + ')'
        }
        setQrCodeURL(qrcodeTotpUrl);
        setQrLoading(false);
      } catch (e) {
        setQrError(true);
      }
    };
    cognitoUserInfo && getQRCode();
    // eslint-disable-next-line
  }, [cognitoUserInfo]);

  return (
    <div className="App-2FA-container">
      {currentMFASetting === "SOFTWARE_TOKEN_MFA" && (
        <div className="confirmed-setup-msg">
          {"You're registered "}
          <CheckIcon
            className="checkmark"
            fontSize="small"
            sx={{ color: "#D0F31A" }}
          />
          <div>
            {
              " Re-registering will make your previous QR code and existing two-factor setup obsolete."
            }
          </div>
        </div>
      )}
      {!appSuccess && (
        <>
          <div className="subheader">
            <b>To set up two factor authentication</b>, download one of these
            supported applications onto your mobile device:
          </div>
          <div className={"totp-images"}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              {applicationIcons.map((icon) => (
                <Grid item key={icon.className}>
                  <img
                    className={`icon ${icon.className}`}
                    src={icon.img}
                    alt={icon.label}
                    width={icon.width}
                  />
                  <p className={`label ${icon.className}`}>{icon.label}</p>
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      )}
      {appSuccess ? (
        <div className="success-msg">
          <CheckIcon fontSize="large" sx={{ color: "#D0F31A" }} />
          <span className="text">App 2FA was setup successfully.</span>{" "}
        </div>
      ) : (
        <>
          <div className="qr-container">
            <div className="description-text">
              <b> Once you have it installed, </b> use the app to scan in this
              QR code or enter key manually. Then enter the resulting code in the box below.
            </div>
            <div className="the-code">
              {qrLoading ? (
                <Loader />
              ) : qrError ? (
                <span className="qr-error">{"Error generating QR code"}</span>
              ) : (
                <QRCodeCanvas value={qrCodeURL} size={128} includeMargin />
              )}
            </div>
          </div>
          <div className="manual-qr-code">
            <div>
              <button
                className="manual-qr-code"
                onClick={() => handleManualQRCode()}
                  >
                {"Setup key manually »"}
              </button>
                {showManualQRcode && <div className="manual-qr-code"> <div className="account-name">Account Name: {cognitoUserInfo.attributes.email} </div> <div className="key">Key: {totpToken} </div></div>}
            </div>
          </div>

          <div className="verification-code-container">
            <div className="input-label">Verification code </div>
            <input
              type="text"
              value={verificationCode}
              onKeyDown={(e) => e.key === "Enter" && handleApp2FASetup()}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Numeric code"
            />
            <div className="confirm-btn-container">
              <button
                className="confirm-btn"
                onClick={() => handleApp2FASetup()}
              >
                {submitLoading ? <Loader /> : "Confirm Code"}
              </button>
              {appError && (
                <div className="error-msg app">
                  <ErrorIcon className="err-icon" /> {appError}
                </div>
              )}
              {currentMFASetting !== "SOFTWARE_TOKEN_MFA" && (
                <div className="fine-print">
                  {"* Enabling App 2FA will disable SMS 2FA"}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App2FA;
