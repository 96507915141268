import React, { useState, useEffect } from "react";
import "../../../styles/pages/Preferences/Sms2FA.scss";
import { postUserMFASetting } from "../../../api/ApiClient";
import { Loader } from "../../../components/Loader/Loader";
import CheckIcon from "@mui/icons-material/Check";
import TooltipIcon from "../../../assets/icons/tooltip.svg";
import Tooltip from "../../../components/Tooltip/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

const Sms2FA = ({
  cognitoUserInfo,
  currentMFASetting,
  setCurrentMFASetting,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [smsError, setSMSError] = useState("");
  const [smsSuccess, setSmsSuccess] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState();

  useEffect(() => {
    if (cognitoUserInfo?.attributes?.phone_number) {
      const parsedNumber = parsePhoneNumber(
        cognitoUserInfo.attributes.phone_number
      );
      setPhoneCountry(parsedNumber.country);
    }
  }, [cognitoUserInfo]);

  const handleSms2FASetup = async () => {
    setSubmitLoading(true);
    setSMSError("");
    try {
      await postUserMFASetting("sms");
      setSmsSuccess(true);
      setCurrentMFASetting("SMS_MFA");
    } catch {
      setSMSError("An error has occurred");
    }
    setSubmitLoading(false);
  };

  return (
    <div className="SMS-2FA-container">
      {currentMFASetting === "SMS_MFA" && (
        <div className="confirmed-setup-msg">
          {"You're registered for SMS 2FA "}
          <CheckIcon
            className="checkmark"
            fontSize="small"
            sx={{ color: "#D0F31A" }}
          />
        </div>
      )}
      <div className={"subheader phone"}>
        <div className="phone-text">{"Your mobile phone number:  "}</div>
        {phoneCountry && (
          <div className="country-flag">
            {flags[phoneCountry]({ title: "" })}
          </div>
        )}
        <div className="formatted-number">{` ${formatPhoneNumberIntl(
          cognitoUserInfo?.attributes?.phone_number
        )}`}</div>
        <div className="tooltip-container">
          <Tooltip
            content={
              "If you wish to change your phone number, please reach out to your administrator."
            }
            className={"phone-tooltip"}
            direction={"right"}
          >
            <img className="tooltip-icon" src={TooltipIcon} alt="Info" />
          </Tooltip>
        </div>
      </div>
      {smsSuccess ? (
        <div className="success-msg">
          <CheckIcon fontSize="large" sx={{ color: "#D0F31A" }} />
          <span className="text">SMS 2FA was setup successfully.</span>{" "}
        </div>
      ) : (
        <div className="confirm-btn-container sms">
          <button
            className={`confirm-btn ${
              currentMFASetting === "SMS_MFA" && "disabled"
            }`}
            onClick={() => handleSms2FASetup()}
            disabled={currentMFASetting === "SMS_MFA"}
          >
            {submitLoading ? (
              <Loader />
            ) : currentMFASetting === "SMS_MFA" ? (
              "SMS Enabled"
            ) : (
              "Enable SMS 2FA"
            )}
          </button>
          {smsError && (
            <div className="error-msg sms">
              <ErrorIcon className="err-icon" />
              {smsError}
            </div>
          )}
          {currentMFASetting !== "SMS_MFA" && (
            <div className="fine-print">
              {"* Enabling SMS 2FA will disable App 2FA"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Sms2FA;
